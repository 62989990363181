@include respond-above(xxxl) {
  .container {
      max-width: 1650px;
  }
}
:root {
    &[font-level="2"] {
        font-size: 18px;

        .font-increase {
            pointer-events: none;
            border-color: #cbcbcb;

            svg path {
                fill: #cbcbcb;
            }
        }
    }

    &[font-level="1"] {
        font-size: 17px;
    }

    &[font-level="-1"] {
        font-size: 15px;
    }

    &[font-level="-2"] {
        font-size: 14px;

        .font-decrease {
            pointer-events: none;
            border-color: #cbcbcb;

            svg path {
                fill: #cbcbcb;
            }
        }
    }
}
:root {
    --green-dark: #47763B;
    --blue-dark-hover: #142f46;
    --gold: #C8A791;
    --orange-hover: #cb6000;
    --yellow-light: #FFFFCC;
    --green-light: #07A7B0;
    --green-light-hover: #08939b;
}
html[dir=rtl]{
    direction: rtl
}

html[dir=ltr] {
    direction: ltr
}
body.grayscale {
    overflow: hidden;
    & > *:not(.vote-box-wrapper) {
        -webkit-filter: grayscale(100%) brightness(95%) contrast(120%);
        -o-filter: grayscale(100%) brightness(95%) contrast(120%);
        filter: grayscale(100%) brightness(95%) contrast(120%);

        img {
            filter: gray;
            /* just for IE6-9 */
            -webkit-filter: grayscale(100%);
            /* The WebKit kernel supports well */
            -moz-filter: grayscale(100%);
            /* Other kernel does not support for future compatibility, writing */
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
            /* Standard */
        }
    }
}
//// vote box
.vote-box-wrapper {
    .vote-box {
        width: 320px;
        background: #fff center center;
        padding: 25px;
        margin-bottom: 45px;
        position: fixed;
        left: -320px;
        bottom: 120px;
        z-index: 999;
        border-radius: 6px;
        transition: all 0.5s cubic-bezier(0.55, 0.55, 0, 1.02);
        color: #636569;

        .vote-icon {
            display: block;
            margin: 0.5rem auto;

        }
        .custom-checkbox-box {
            border: 1px solid #47763B;
        }

        .vote-box-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 0px;
            color: $green-dark;
            text-align: center;

            + p {
                font-size: 0.875rem;
                color: #3B3B3C;
                text-align: center;
            }
        }

        .custom-control.custom-radio {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include respond-below(xl) {
            bottom: 60px;
            padding: 15px;
            width: 300px;
        }
    }

    .users-votes {
        margin-top: 1rem;
        text-align: center;

        .users-votes-count {
            font-size: 12px;
            font-weight: 600;
            margin: 0;
        }

        p {
            font-size: 12px;
            margin: 0;
        }
    }

    .vote-toggle-btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        position: fixed;
        left: -90px;
        bottom: 100px;
        z-index: 999;
        width: 40px;
        height: 40px;
        padding: 7px;
        background: #646F4D;
        border: 1px solid rgba($gold, 0.5);
        border-radius: 50px;
        transition: all 0.5s cubic-bezier(0.55, 0.55, 0, 1.02);

        span {
            display: none;
            transition: all 0.5s cubic-bezier(0.55, 0.55, 0, 1.02);
            font-size: 0.875rem;
            color: #fff;
        }

        &:hover {
            width: 150px;

            span {
                display: inline-block;
            }
        }

        @include respond-below(xl) {
            bottom: 40px;
        }
    }

    .vote-input-group {
        margin-bottom: 7px;
    }

    &.active-voting {
        &.active {
            .vote-box {
                left: 25px;
                box-shadow: 0 0 16px rgba(#527442, 0.33);

                @include respond-below(xl) {
                    left: 15px;
                }
            }

            .vote-toggle-btn {
                width: 150px;

                span {
                    display: inline-block;
                }
            }
        }

        .survey-widget-item-bar {
            display: block;
            width: 100%;
            height: 9px;
            background: #F5F5F5;

            .survey-widget-item-progress {
                display: block;
                background: $gold;
                height: 9px;
            }
        }

        .survey-widget-box {
            .custom-control.custom-radio {
                padding: 0;
                margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            label {
                font-weight: 600;
            }
        }

        .vote-toggle-btn {
            left: 25px;

            @include respond-below(xl) {
                left: 15px;
            }
        }
    }
}

///// opendata bullets 
.custom-data-bullet {
    background: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%2346763a'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 17px;
    height: 13px;
    background-position: center;
}
    /// fonts
    @font-face {
        font-family: "Janna-LT";
        src: url("@/assets/fonts/Janna-Bold.ttf");
        font-weight: 600;
    }

    @font-face {
        font-family: "Janna-LT";
        src: url("@/assets/fonts/Janna-LT-Regular.ttf");
        font-weight: 400;
    }

    @font-face {
        font-family: "BemboStd";
        src: url("@/assets/fonts/BemboStd.otf");
        font-weight: 400;
    }

    @font-face {
        font-family: "BemboStd";
        src: url("@/assets/fonts/BemboStd-Bold.otf");
        font-weight: 600;
    }
    /// animation
    @keyframes moving {
        from {
            background-position: 0px bottom;
        }

        to {
            background-position: 864px bottom;
        }
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    button {
        border: none;
        background: none;
        transition: all ease-in-out .25s;
    }

    body {
        direction: inherit;
        font-family: "BemboStd", 'Janna-LT', sans-serif;
    }

    .lity {
        z-index: 999990;
    }
    ////// animate duration
    .animated {
        animation-duration: 1.25s;
    }

    .hero-animate {
        transform: scale(1);
    }

    .top-btn {
        position: fixed;
        inset-inline-end: 60px;
        bottom: 150px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        z-index: 99999;
        opacity: 0.6;
        transition: opacity ease-in-out .25s;

        &__icon {
            transform: rotate(90deg);
            border: 1px solid #fff;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        &__txt {
            font-size: .85rem;
            font-weight: 600;
            color: #fff;
        }

        &:hover {
            opacity: 1;
        }
    }
    //////////////////
    .titl-with-shape {
        color: $gold;
        display: inline-flex;
        flex-direction: column;
        // align-items: end;
        &::before {
            content: '';
            width: 18px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.434' height='16.215' viewBox='0 0 21.434 16.215'%3E%3Cpath d='M197.108,220.379c-.253-.156-.519-.319-.8-.484l-.506-.3-.5-.292c-.259-.149-.527-.3-.8-.454a.01.01,0,0,0-.009,0c-.271-.156-.551-.308-.836-.461a.157.157,0,0,0-.033-.016c-.286-.159-.58-.316-.881-.472s-.633-.328-.96-.494c-.159-.079-.321-.159-.483-.242l-.009,0,0,0,0,0a62.086,62.086,0,0,0-9.457,5.675,56.8,56.8,0,0,1,9.465,8.433,56.8,56.8,0,0,1,9.465-8.433c-.371-.288-1.408-1.049-3.02-2.064C197.532,220.641,197.323,220.512,197.108,220.379Z' transform='translate(-180.568 -216.219)' fill='none' stroke='%23c8a791' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .more-link {
        color: $green-dark;
        display: inline-flex;
        align-items: center;
        gap: 1.2rem;
        transition: color ease-in-out .25s,gap ease-in-out .25s;
        z-index: 9;

        &::after {
            content: '';
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='13.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%2347763b'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 13px;
            transition: background-image ease-in-out .25s;

            @include ltr(){
                transform : rotateZ(180deg)
            }
        }

        &:hover {
            color: $gold;
            gap: 0.8rem;

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='13.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%23C8A791'/%3E%3C/svg%3E%0A");
            }
        }

        @include respond-below(xl) {
            gap: .6rem;
            font-size: 0.8rem;

            &::after {
                width: 17px;
                height: 9px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.243' height='9.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%2347763b'/%3E%3C/svg%3E%0A");
            }

            &:hover {
                gap: 0.5rem;

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.243' height='9.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%23C8A791'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
    /////slider-btns
    .swiper-button-next, .swiper-button-prev {
        &::after {
            content: '' !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30.243' height='13.501' viewBox='0 0 30.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H37.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.027,1.027,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.251)' fill='%2347763b'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            width: 30px;
            height: 13px;

            @include respond-below(xl) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='9.501' viewBox='0 0 30.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H37.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.027,1.027,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.251)' fill='%2347763b'/%3E%3C/svg%3E%0A");
                width: 20px;
                height: 9px;
            }
            // @include respond-below(lg) {
            //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.243' height='6.501' viewBox='0 0 30.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H37.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.027,1.027,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.251)' fill='%2347763b'/%3E%3C/svg%3E%0A");
            //   background-repeat: no-repeat;
            //   width: 17px;
            //   height: 6px;
            // }
        }

        @include respond-below(md) {
        }
    }
.card-icon {
    position: relative;

    svg {
        transition: all ease-in-out .25s;
        margin-top: -0.5rem;
    }

    &.share-btn {
        svg {
            margin-top: 0rem;
        }

        .spga-share-list {
            left: calc(100% + 20px);

            &::before {
                left: 20%;
            }
        }
    }

    .spga-share-list {
        list-style: none;
        display: flex;
        margin-bottom: 0;
        background: rgba(#47763B, 0.9);
        padding: 3px;
        align-items: center;
        position: relative;
        height: 30px;
        border-radius: 5px;
        position: absolute;
        left: 50%;
        transform: translate(-40%);
        top: -50px;
        transition: all .25s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 999;

        li {
            display: block;
            line-height: 0;
            padding: 0 5px;

            a {
                display: block;
                line-height: 0;
                padding: 3px;
                transition: all .25s ease-in-out;

                svg {
                    margin: 0;

                    path {
                        transition: fill ease-in-out 0.25s;
                    }
                }

                &:hover {
                    transform: translateY(-3px);

                    svg path {
                        fill: $gold;
                    }
                }
            }
        }

        &::before {
            content: "";
            display: inline-block;
            border: 10px solid rgba(#47763B, 0.8);
            border-bottom: 0;
            border-right-color: transparent;
            border-left-color: transparent;
            @include center("h");
            bottom: -7px;
        }
    }

    &:hover .spga-share-list {
        top: -40px;
        visibility: visible;
        opacity: 1;
    }
}
    ///// end slider-btns
.opportunity-card {
    padding: 20px 28px;
    border-radius: 10px;
    box-shadow: 0px 0px 25px rgba(#000000, 0.06);
    background-color: #fff;
    // min-width: 350px;
    .card-info {
        display: flex;
        justify-content: space-between;
        color: $green-dark;
        font-size: 0.875rem;

        .card-icon {
            display: flex;
            align-items: center;
            gap: 8px;
            transition: all ease-in-out .25s;
            color: $green-dark;
            position: relative;
            svg {
                margin-top: -0.5rem;
            }
        }

        @include respond-below(md) {
            font-size: 0.815rem;

            .card-icon {
                gap: 4px;
            }
        }
    }

    img {
        border-radius: 20px;
        display: block;
        margin-inline: auto;
        margin-block: 1rem;
    }

    .opportunity-card--titl {
        font-size: 1.25rem;
        font-weight: bold;
        color: #3B3B3C;
        margin-top: 1rem;
        margin-bottom: 0.6rem;
    }

    p {
        color: #636569;
        margin-bottom: 1.25rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        height: 72px;

        @include respond-below(xxxl) {
            font-size: 1rem;
            margin-bottom: .75rem;
        }

        @include respond-below(lg) {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            max-width: 400px;
        }
    }

    .opportunity-card--btn {
        border: 1px solid $gold;
        color: $gold;

        &::after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='13.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%23c8a791'/%3E%3C/svg%3E%0A");
        }

        @include respond-below(xxxl) {
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.243' height='10.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%23c8a791'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

    .opportunity-swiper, .competition-swiper {
        padding: 20px 10px;
    }

    .card-date {
        color: $green-dark;

        span:nth-of-type(1) {
            padding-inline-end: 0px;

            &::after {
                content: '';
                display: inline-block;
                margin-inline: 8px;
                width: 15px;
                height: 12px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.933' height='6.647' viewBox='0 0 8.933 6.647'%3E%3Cpath d='M6411.873,1046.495s-1.087.518-2.214,1.2-2.294,1.525-2.294,1.525,1.167.806,2.294,1.786,2.214,2.137,2.214,2.137,1.037-1.156,2.144-2.137a26.412,26.412,0,0,1,2.281-1.786s-1.176-.844-2.281-1.525S6411.873,1046.495,6411.873,1046.495Z' transform='translate(-6407.365 -1046.495)' fill='%23497a39'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;

                @include respond-below(md) {
                    margin-inline: 4px;
                    width: 9px;
                    height: 7px;
                }
            }
        }
    }

.card-date-cv {
    color: $green-dark;

    span:nth-of-type(1) {
        padding-inline-end: 0px;

        &::before {
            content: '';
            display: inline-block;
            margin-inline: 8px;
            width: 15px;
            height: 12px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.933' height='6.647' viewBox='0 0 8.933 6.647'%3E%3Cpath d='M6411.873,1046.495s-1.087.518-2.214,1.2-2.294,1.525-2.294,1.525,1.167.806,2.294,1.786,2.214,2.137,2.214,2.137,1.037-1.156,2.144-2.137a26.412,26.412,0,0,1,2.281-1.786s-1.176-.844-2.281-1.525S6411.873,1046.495,6411.873,1046.495Z' transform='translate(-6407.365 -1046.495)' fill='%23497a39'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;

            @include respond-below(md) {
                margin-inline: 4px;
                width: 9px;
                height: 7px;
            }
        }
    }
}
    ///// main button

.main-btn {
    border: 1px solid #fff;
    border-radius: 55px;
    padding: 8px 16px;
    display: inline-flex;
    align-items: center;
    color: #fff;
    /*transition: background 0.25s ease-in-out, color 0.25s ease-in-out, border 0.25s ease-in-out;*/
    &::after {
        content: '';
        width: 20px;
        height: 15px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='13.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%23fff'/%3E%3C/svg%3E%0A");
        display: inline-block;
        background-repeat: no-repeat;
        margin-inline-start: 30px;
        transition: margin-inline-start .25s ease-in-out,border-color .25s ease-in-out, background-image 0.25s ease-in-out;

        @include ltr() {
            transform: rotateZ(180deg)
        }
    }

    &:hover {
        color: #fff;
        border-color: $gold;
        background-color: $gold;

        &::after {
            margin-inline-start: 45px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='13.501' viewBox='0 0 20.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.252)' fill='%23fff'/%3E%3C/svg%3E%0A");
        }
    }
}
    ///// pagination

    .pagination-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        list-style: none;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        margin-bottom: 0;

        li {
            margin: 5px 10px;
        }

        button, span, a {
            border: 1px solid $gold;
            display: inline-block;
            padding: 0.35rem 1rem;
            background: #fff;
            color: #3D3935;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.5;
            border-radius: 30px;
            cursor: pointer;
            transition: all .25s ease-in-out;
            outline: none;

            &.active {
                background: $gold;
                color: #fff;
                cursor: default;
            }

            &:hover {
                background: $gold;
                color: #fff;
            }
        }
    }
    ///// search-box
    .content-search-box {
        background: $gold;
        color: #fff;
        padding: 0.75rem 4rem 2rem 2rem;
        max-width: 1080px;
        margin-inline: auto;
        color: #fff;

        @include respond-below(xl) {
            //  margin-top: -2rem;
            padding: 0.75rem 2rem 1rem 1rem;
        }

        .search-form-title {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .search-form-box {
            display: flex;
            align-items: center;
            flex: 0 0 100%;
            gap: 1rem;

            @include respond-below(xl) {
                flex-wrap: wrap;
            }

            .mx-datepicker {
                min-width: 150px;
            }

            .mx-input-wrapper {
                path {
                    fill: #FFF;
                }
            }

            input {
                border: 1px solid rgba(61,57,53,.1);
                border-radius: 30px;
                background-color: rgba(#100F0D,.1);
                color: #fff;
                outline: none;
                font-weight: 500;
                padding: 2px 25px;
                max-width: unset;
                height: 40px;
                width: 100%;
                display: block;
                min-width: 110px;

                &::placeholder {
                    color: #fff;
                }
            }

            .search-submit {
                background: #fff;
                color: #3d3935;
                font-weight: 600;
                border: 0;
                border-radius: 25px;
                display: inline-block;
                padding: 5px 20px;
                min-width: 115px;
                transition: all .25s ease-in-out;
                height: 40px;

                &:hover {
                    background-color: #e3e3e3;
                }

                &.btn-clear {
                    background: none;
                    border: 1px solid #fff;
                    color: #Fff;

                    &:hover {
                        background-color: rgba(#FFF, .2);
                    }
                }
            }
        }
    }
    //// primary btn
    .primary-btn {
        min-width: 150px;
        //height: 50px;
        padding: 0.65rem 2.5rem;
        border: 1px solid $gold;
        font-size: 0.875rem;
        color: $gold;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.25s;

        &.colered {
            font-weight: 600;
            background: $gold;
            font-size: 1rem;
            color: #fff;
            display: block;
            margin-inline: auto;
            margin-top: 3.5rem;
        }

        &.with-icon {
            background: $gold;
            color: #fff;

            &::after {
                content: '';
                margin-inline-start: 1rem;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.449' height='20.083' viewBox='0 0 15.449 20.083'%3E%3Cpath d='M16636.934,6385.643a1.937,1.937,0,0,1-1.93-1.931v-11.588a1.936,1.936,0,0,1,1.93-1.93h5.166v-4.007a.629.629,0,0,1,1.258,0v4.007h5.16a1.938,1.938,0,0,1,1.936,1.93v11.588a1.938,1.938,0,0,1-1.936,1.931Zm1.973-9.035a.624.624,0,0,0,0,.886l3.381,3.331a.644.644,0,0,0,.2.134.575.575,0,0,0,.242.046.627.627,0,0,0,.436-.18l3.379-3.331a.628.628,0,0,0-.881-.894l-2.314,2.282v-8.687h-1.246v8.688l-2.311-2.282a.633.633,0,0,0-.891.008Z' transform='translate(-16635.004 -6365.56)' fill='%23fff'/%3E%3C/svg%3E%0A");
                width: 15px;
                height: 20px;
                margin-top: -0.5rem;

                @include respond-below(sm) {
                    margin-inline-start: 0.5rem;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.449' height='17.083' viewBox='0 0 15.449 20.083'%3E%3Cpath d='M16636.934,6385.643a1.937,1.937,0,0,1-1.93-1.931v-11.588a1.936,1.936,0,0,1,1.93-1.93h5.166v-4.007a.629.629,0,0,1,1.258,0v4.007h5.16a1.938,1.938,0,0,1,1.936,1.93v11.588a1.938,1.938,0,0,1-1.936,1.931Zm1.973-9.035a.624.624,0,0,0,0,.886l3.381,3.331a.644.644,0,0,0,.2.134.575.575,0,0,0,.242.046.627.627,0,0,0,.436-.18l3.379-3.331a.628.628,0,0,0-.881-.894l-2.314,2.282v-8.687h-1.246v8.688l-2.311-2.282a.633.633,0,0,0-.891.008Z' transform='translate(-16635.004 -6365.56)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    width: 12px;
                    height: 17px;
                }
            }

            &:hover {
                background-color: #ae8d77;
            }
        }

        &:hover {
            background-color: $gold;
            color: #fff;
        }

        @include respond-below(sm) {
            padding: 0.385rem 0.875rem;
            min-width: 120px;
        }
    }
    ////// twitter

    .twitter-wrapper {
        position: fixed;
        bottom: 150px;
        inset-inline-end: 40px;
        z-index: 999;
        transition: all 0.5s cubic-bezier(0.55, 0.55, 0, 1.02);

        @include respond-below(lg) {
            inset-inline-end: 15px;
        }

        .twitter-dropdown-wrapper {
            position: relative;
            padding-top: 0;
            transition: all 0.25s ease-in-out 0.25s;

            .twitter-dropdown {
                position: absolute;
                width: 300px;
                bottom: 0px;
                box-shadow: 0 0 30px rgb(0 0 0 / 10%);
                border-radius: 4px;
                transform: translate(-70px, -70px) rotateZ(-20deg);
                transform-origin: right bottom;
                transition: all 0.25s ease-in-out;
                opacity: 0;
                visibility: hidden;
                z-index: 30;
                background: #fff;
                inset-inline-end: 60px;
                overflow: hidden;

                & > iframe {
                    transition: all 0.25s ease-in-out;
                    opacity: 0 !important;
                    visibility: hidden !important;
                }
            }

            .twitter-dropdown-toggle {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 55px;
                height: 55px;
                background: #00ACEE;
                border-radius: 50%;
                border: 5px solid #FFF;
                box-shadow: 0 0 25px rgb(0 0 0 / 10%);
                transition: border 0.25s ease-in-out;

                svg {
                    transition: transform 0.25s ease-in-out;
                }

                @include respond-below(lg) {
                    width: 30px;
                    height: 30px;
                    background: #00ACEE;
                    border-radius: 50%;
                    border: 2px solid #FFF;

                    svg {
                        width: 15px;
                    }
                }
            }

            &:hover {
                transition-delay: 0s;

                .twitter-dropdown {
                    opacity: 1;
                    visibility: visible;
                    transform: none;

                    & > iframe {
                        opacity: 1 !important;
                        visibility: visible !important;
                    }
                }

                .twitter-dropdown-toggle {
                    border: 2px solid #FFF;

                    svg {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    ////// start servey
    .servey-wrapper {
        position: fixed;
        bottom: 250px;
        inset-inline-end: 40px;
        color: #fff;
        border-radius: 30px;
        background-color: #C8A791;
        width: 55px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        transition: width ease-in-out 0.25s, border ease-in-out 0.25s;
        z-index: 999;
        backdrop-filter: brightness(25%) blur(8px) opacity(90%);
        mix-blend-mode: color-dodge;

        .servey-icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        .servey-txt {
            font-size: 0.65rem;
            display: none;
            color: #fff;
        }

        &:hover {
            width: 130px;
            gap: .8rem;
            border: none;

            .servey-txt {
                display: block;
            }
        }

        @include respond-below(lg) {
            width: 30px;
            height: 30px;
            inset-inline-end: 15px;

            .servey-icon svg {
                width: 14px;
            }
        }
    }
    /// statics
    .statistics-list {
        padding: 4.25rem 0;

        &--row {
            --grid-col: 3;
            margin: 4rem 0 5rem;
            display: grid;
            grid-template-columns: repeat(var(--grid-col), 1fr);
            grid-gap: 4rem;

            @include respond-below(xxxl) {
                grid-gap: 2rem;
            }

            @include respond-below(xxl) {
                --grid-col: 3;
                grid-gap: 1.7rem;
            }

            @include respond-below(lg) {
                --grid-col: 2;
                margin: 2rem 0 3rem;
            }

            @include respond-below(md) {
                --grid-col: 1;
            }

            .statistics-item {
                display: flex;
                justify-content: center;
            }
        }
    }


    .statistics-item:hover {
        z-index: 9;
    }

    .statistics-item--wrapper:hover + .statistics-item--home-item-bg {
        transform: translate(-50%, -50%) scale(1) !important;
    }

    .statistics-item--home-item-bg {
        transform: translate(-50%, -50%) scale(.2) !important;
        top: 50%;
        left: 50%;
    }

.cookie {
    // Bar style
    &__bar {
        direction: inherit;
        font-family: "BemboStd", "Janna-LT", sans-serif;


        &--bottom {
            background-color: #e0cec2e6;
            margin: 0px;
            padding: 24px;
            animation-duration: 0.4s;
            animation-delay: 0s;
        }

        &--top {
        }

        &__postpone-button {
        }

        &__content {
            span {
                font-weight: 700;
                padding: 0 0 6px;
                font-size: 18px;
            }

            p {
                margin-bottom: 0;
                padding-top: 10px;
                font-weight: 500;
                font-size: 15px;

                a {
                    color: #2079c1;
                    text-decoration: none;
                    font-weight: bold;
                }
            }

            color: black;
        }

        &__buttons {
            gap: 4px;
            flex-flow: row-reverse;

            &__button {
                &--accept {
                    background: #41652e;
                }

                &--decline {
                    background: #c04242;
                }
            }
        }
    }
    // Floating style
    &__floating {
        &--bottom-left {
        }

        &--bottom-right {
        }

        &--top-right {
        }

        &--top-left {
        }

        &__postpone-button {
        }

        &__content {
        }

        &__buttons {

            &__button {
                &--accept {
                }

                &--decline {
                }
            }
        }
    }
}