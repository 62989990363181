.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    text-align: center;
    padding: 0.65rem 2.5rem;
    border: 1px solid transparent;
    border-radius: 50px;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    background: transparent;
    color: inherit;

    &--gold {
        background-color: $gold;
        color: #fff;

        &:hover {
            background-color: #ae8d77;
            color: #fff;
        }

        &[disabled] {
            cursor: auto;
            background: #dfccbf !important;
        }
    }

    &--gold-outline {
        border-color: $gold;
        color: $gold;

        &:hover {
            background-color: rgba($gold, .15);
            color: $gold;
        }
    }

    &--full-width {
        display: flex;
        width: 100%;
    }
}
///// custom about list
