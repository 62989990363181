
                    @import "@/assets/scss/_mixins.scss";
                    @import "@/assets/scss/_vars.scss";
                



.top-header--icon {
    list-style: none;

    li {
        padding: 7px;
        position: relative;

        .settings-dropdown {
            position: absolute;
            top: calc(100% + 30px);
            min-width: 250px;
            max-width: 90vw;
            background: #fff;
            border-radius: 5px;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all .25s ease-in-out;
            padding: 1.875rem 2.5rem;
            box-shadow: 0px 0px 15px rgba(#000, 0.1);

            @include rtl() {
                left: 0;
            }

            @include ltr() {
                right: 0;
            }

            .font-settings-color-contrast-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .font-settings {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .font-settings {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .font-settings {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .font-increase, .font-decrease {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: transparent;
                border: 1px solid $gold;
                padding: 0;
                cursor: pointer;
                outline: none;
                transition: all .25s ease-in-out;

                svg {
                    width: 12px;
                    height: auto;

                    path {
                        fill: $gold;
                    }
                }
            }

            .current-font-size {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                border: none;
                text-align: center;
                font-weight: 700;
                color: $gold;
                display: inline-flex;
                justify-content: center;
                /*align-items: center;*/
                margin: -5px 1rem 0;
                font-size: 1.5rem;
            }

            .color-contrast {

                @include rtl() {
                    padding-right: 1rem;
                    margin-right: 1rem;
                    border-right: 1px solid rgba(112,112,112,.2);
                }

                @include ltr() {
                    padding-left: 1rem;
                    margin-left: 1rem;
                    border-left: 1px solid rgba(112,112,112,.2);
                }

                button {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    font-weight: 400;
                    background: none;
                    gap: 0.5rem;
                    border: 0;
                    cursor: pointer;
                    outline: none;
                    color: #202020;
                    transition: all .25s ease-in-out;
                    font-size: var(--txt-link);
                }

                .icon-box {
                    display: inline-flex;
                    width: 38px;
                    height: 20px;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    border-radius: 30px;
                    border: 2px solid $gold;
                    position: relative;

                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        background-color: #202020;
                        border-radius: 100%;
                        position: absolute;
                        top: 3px;
                        right: 4px;
                        transition: all .25s ease-in-out;
                    }
                }
            }
            /*border-radius: 10px;*/
        }
    }

    .setting-icon.active {
        .settings-dropdown {
            top: calc(100% + 10px);
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }
}
.grayscale .top-header--icon li .settings-dropdown .color-contrast .icon-box:after {
    right: 20px;
}

