.contact-info {
    h2 {
        font-size: 1.625rem;
        font-weight: 600;
        color: #3D3935;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 3rem;

        @include respond-below(md) {
            justify-content: center;
        }

        .contact-ways {
            padding: 0.75rem 1rem;
            text-align: center;

            h4 {
                font-size: 1rem;
                font-weight: 600;
                color: #484848;
            }

            a {
                font-size: 1.375rem;
                font-weight: 600;
                color: $green-dark;
                transition: color ease-in-out 0.25s;

                &:hover {
                    color: #2C5821;

                    path {
                        fill: #2C5821;
                    }
                }
            }

            &.social {
                text-align: center;

                svg path {
                    transition: fill ease-in-out 0.25s;
                }

                a:nth-last-of-type(2) {
                    margin-inline: 0.75rem;
                }
            }
        }
    }
}
