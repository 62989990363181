
                    @import "@/assets/scss/_mixins.scss";
                    @import "@/assets/scss/_vars.scss";
                


.d-rtl {
    direction: rtl;
    text-align: right;
}

.rating-sec {
}

.rating-title {
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    background-image: url(../assets/images/pattern-bg.svg);
    background-size: cover;
}

    .rating-title p {
        font-size: 24px;
        font-weight: bold;
    }

.rating-btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.rating-sec .btn {
    text-align: center;
    padding: 10px 25px;
    border-radius: 40px;
    min-width: 80px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.btn-fill {
    color: #fff;
    background-color: #B99D84;
    border: 1px solid #B99D84;
}

.btn-fill.selected {
    background-color: #8A6F5A; /* Change to your desired color */
    border-color: #8A6F5A;
}

.btn-o-fill {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
}

.rating-info {
    padding: 50px;
    color: #fff;
   /* background-color: #47763B;*/
}

 .submitSec {
    text-align: center;
}

.rating-info p {
    font-size: 30px;
    margin-top: 0;
}

    .rating-info .btn-o-fill {
        border-color: #fff;
    }

.btn-fill:hover {
    background-color: #dab28e;
    border-color: #dab28e;
}

.btn-o-fill:hover {
    background-color: #e4e4e4;
    border-color: #bfbdbd;
}

.unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 50px;
}

    .unstyled li {
        margin: 20px 0;
    }

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

    .styled-checkbox + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-size: 18px;
    }

        .styled-checkbox + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: white;
        }

    .styled-checkbox:checked + label:before {
        background: #b99d84;
    }

    .styled-checkbox:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

        .styled-checkbox:disabled + label:before {
            box-shadow: none;
            background: #ddd;
        }

    .styled-checkbox:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
    }

.d-rtl .styled-checkbox + label:before {
    margin-right: 0px;
    margin-left: 10px;
}

.d-rtl .styled-checkbox:checked + label:after {
    left: auto;
    right: 13px;
}

@media(max-width: 480px) {
    .rating-title {
        padding: 20px;
        gap: 0;
    }

        .rating-title p {
            font-size: 20px;
        }

    .rating-info {
        padding: 20px;
    }

        .rating-info p {
            font-size: 22px;
        }

    .unstyled {
        margin-bottom: 30px;
    }
}


