
                    @import "@/assets/scss/_mixins.scss";
                    @import "@/assets/scss/_vars.scss";
                

.footer {
    background: linear-gradient(180deg, rgba(82,116,66,1) 0%, rgba(32,80,86,1) 100%);
    text-align: center;
    z-index: 99;
    color: #fff;
    overflow: hidden;
    padding: 4rem 0 2rem;
    position: relative;

    @include respond-below(sm) {
        padding: 2rem 0 1rem;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(@/assets/images/footer-bg.svg);
        background-position: bottom;
        background-repeat: repeat-x;
        opacity: 0.5;
        z-index: -1;
        mix-blend-mode: overlay;
        animation-name: moving;
        animation-duration: 60s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @include respond-below(sm) {
            // background-size: contain;
        }
    }

    &__logo {
        //margin-top: 1.4rem;
        @include respond-below(xxl) {
            img {
                max-height: 80px;
            }
        }

        @include respond-below(xl) {
            img {
                width: 280px;
            }
        }

        @include respond-below(sm) {
            img {
                width: 200px;
            }
        }
    }

    &__contact {
        margin-top: 3.5rem;
        text-align: center;

        h5 {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        p {
            font-size: .8rem;
            opacity: 0.7;
            margin-bottom: 2rem;
        }

        @include respond-below(xxl) {
            margin-top: 2rem;

            p {
                margin-bottom: 1rem;
            }
        }

        @include respond-below(sm) {
            margin-top: .8rem;

            h5 {
                font-size: 1rem;
            }

            p {
                font-size: .6rem;
            }
        }

        &__info {
            position: relative;
            max-width: 500px;
            height: 60px;
            margin-inline: auto;

            &::after {
                content: '';
                @include center('v');
                width: 20px;
                height: 15px;
                inset-inline-start: 10px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.998' height='15.998' viewBox='0 0 19.998 15.998'%3E%3Cpath d='M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z' transform='translate(-3 -6)' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                border: 1px solid #fff;
                padding: 1rem;
                border-radius: 90px;
                color: #fff;
                text-indent: 1.25rem;
                font-size: 0.875rem;
                background: rgba(#fff, 0.07);

                &::placeholder {
                    font-size: 0.875rem;
                    color: #fff;
                    text-indent: 1.25rem;
                }
            }

            button {
                @include center('v');
                inset-inline-end: 10px;
                font-size: 14px;
                font-weight: 600;
                background: #fff;
                color: #105857;
                padding: 10px 20px;
                border: 1px solid #fff;
                border-radius: 90px;
            }
            // &__box {
            //   background: rgba(#FFF, 0.05);
            //   border-radius: 5px;
            //   padding: 25px 10px;
            //   min-width: 177px;
            //   transition: background ease-in-out 0.25s;
            //   backdrop-filter: blur(3.5px) brightness(1.1);
            //   color: #fff !important;
            //   svg {
            //     height: 20px;
            //     width: auto;
            //     opacity: 0.8;
            //     transition: opacity ease-in-out .25s;
            //   }
            //   p {
            //     margin-top: 0.3rem;
            //     margin-bottom: .85rem;
            //     opacity: 0.8;
            //     transition: opacity ease-in-out .25s;
            //   }
            //   span {
            //     font-size: 1rem;
            //     display: block;
            //     line-height: 1;
            //     opacity: 0.8;
            //     transition: opacity ease-in-out .25s;
            //   }
            //   &:hover {
            //     background: rgba(#FFF, 0.2);
            //     p {
            //       opacity: 1;
            //     }
            //     svg {
            //       opacity: 1;
            //     }
            //     span {
            //       opacity: 1;
            //     }
            //   }
            //   @include respond-below(xxl) {
            //     padding: 9px 5px;
            //     min-width: 120px;
            //     svg {
            //       height: 17px;
            //     }
            //     p {
            //       margin-top: 0.2rem;
            //       margin-bottom: .55rem;
            //     }
            //     span {
            //       font-size: 0.8rem;
            //     }
            //   }
            // }
            @include respond-below(sm) {
                flex-wrap: wrap;
                gap: 1rem;

                .mail-box, .phone-box {
                    padding: 15px 6px;

                    svg {
                        width: 22px;
                    }

                    span {
                        font-size: .9rem;
                    }
                }
            }
        }
    }

    &__social {
        margin-top: 3.25rem;

        h6 {
            font-size: .875rem;
            margin-bottom: .75rem;
        }

        &__links {
            display: flex;
            justify-content: center;
            gap: 0.5rem;

            a {
                border: 1px solid #fff;
                border-radius: 50%;
                width: 34px;
                height: 34px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transition: all ease-in-out .25s;
                opacity: .6;

                path {
                    transition: fill ease-in-out .25s;
                }

                &:hover {
                    opacity: 1;
                    background-color: #fff;

                    path {
                        fill: $green-dark
                    }
                }
            }
        }

        @include respond-below(xxl) {
            margin-top: 2rem;
        }

        
    }

    &__links {
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin-top: 3.5rem;

        @include respond-below(xxl) {
            margin-top: 2rem;
        }

        @include respond-below(xl) {
            gap: 1.5rem;
            flex-wrap: wrap;
        }

        @include respond-below(lg) {
            gap: .7rem;
            flex-wrap: wrap;
            margin-top: 3rem;
        }

        @include respond-below(sm) {
            margin-top: 2rem;
            flex-direction: column;
        }

        li {
            position: relative;

            a {
                color: #fff;
                transition: opacity ease-in-out .25s;
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                gap: .5rem;
                opacity: .6;

                @include respond-below(lg) {
                }

                &:hover {
                    opacity: 1;
                }

                &::before {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    top: 0;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 23px;
                    height: 15px;
                    transition: opacity ease-in-out .25s;
                }

                @include respond-below(sm) {
                    display: inline-block;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &__useful-links {
        margin-top: 1.3rem;
        display: flex;
        justify-content: center;
        gap: 1.4rem;

        @include respond-below(xxl) {
            margin-top: 1rem;
        }

        @include respond-below(lg) {
            flex-wrap: wrap;
            gap: .7rem;
        }

        @include respond-below(sm) {
        }

        a {
            color: #fff;
            transition: opacity ease-in-out .25s;
            opacity: .6;

            &:hover {
                opacity: 1;
            }
        }
    }

    .copy-right {
        margin-bottom: 0;
        margin-top: 3.5rem;

        @include respond-below(xxl) {
            margin-top: 2rem;
        }

        @include respond-below(sm) {
            font-size: .85rem;
        }
    }
}
