.header {
    position: fixed;
    width: 100%;
    z-index: 9999;
    padding: 15px 0;
    transition: all .25s ease-in-out;

    @include respond-below(xxxl) {
        padding: 15px 0;
    }

    @include respond-below(lg) {
        position: absolute;
    }

    @include respond-above(lg) {
        &.scroll-active {
            background-color: #FFF;
            box-shadow: 0 10px 25px rgba(#000, .1);
            .colored-logo {
                display: inline-block;
            }
            .white-logo {
                display: none;
            }
            .search-tap {
                border-color: #b9b9b9;
                color: #4D4E4E;
                &::placeholder {
                        color: #4D4E4E;
                }
            }
            .search-icon {
                border-color: #b9b9b9;
                /*color: #4D4E4E;*/
            }
        }
    }
    .white-logo {
        display: inline-block;
    }
    .colored-logo {
        display: none;
    }
    &.active {
        .header--mobile-overlay {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        .main-nav {
            .nav--bar {
                @include ltr() {
                    left: 0px;
                }

                @include rtl() {
                    right: 0px;
                }
            }
        }
    }
}
/// start search
.header-search-box {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99999;
    top: -400px;
    display: flex;
    color: #3d3935;
    font-size: 14px;
    font-weight: 600;
    transition: all .75s cubic-bezier(0.55, 0.55, 0, 1.02);
    padding: 0 20px;

    &.active {
        top: 0;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 380px;
        background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(22, 25, 28, 0.95) 50%, transparent);
    }

    .header-search-content {
        max-width: 1000px;
        position: relative;
        z-index: 1;
        margin: 60px auto;
        width: 800px;

        @include respond-below(sm) {
            margin: 30px auto;
            width: 90vw;
        }
    }

    .close-header-search {
        position: relative;
        bottom: 5px;
        opacity: .5;
        transition: all .25s ease-in-out;
        margin-left: 15px;

        /*@include respond-below(sm) {
            bottom: 2px;
            margin-left: 5px;
            svg {
                width: 15px;
                height: auto;
            }
        }*/
    }

    input {
        color: #fff;
        font-weight: 600;
        border: 0;
        height: 90px;
        outline: none;
        transition: all .5s cubic-bezier(0.55, 0.55, 0, 1.02);
        width: calc(100% - 145px);
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        padding: 7px 40px;
        padding-right: 0;
        background: transparent;
        border-bottom: 2px solid rgba(255,255,255,.2);
        font-size: 32px;
        position: relative;
        top: 1px;

        @include respond-below(md) {
            width: calc(100% - 110px);
            font-size: 24px;
            padding: 3px 20px;
            height: 50px;
        }

        @include respond-below(sm) {
            font-size: 18px;
        }
    }

    button[type='submit'] {
        width: 65px;
        aspect-ratio: 1.5/1;
        background: #47763B;
        background-size: cover;
        clip-path: url(#search-shape);

        defs path {
            transform: translate(2px, 2px);
        }
    }
}
.top-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    @include respond-between(sm, lg) {
        justify-content: flex-start;
        gap: 1.5rem;
    }
    .brand-logo {
        display: inline-block;
        position: relative;
        z-index: 3;
        img {
            width: 190px;
            height: auto;
        }
        @include respond-below(xxxl) {
            img {
                width: 160px;
            }
        }
        @include respond-below(xxl) {
            img {
                width: 140px;
            }
        }
        @include respond-below(xl) {
            img {
                width: 130px;
            }
        }
    }
    .header--nav-btn {
        display: none;
        position: relative;
        z-index: 3;
        @include respond-between(sm, lg) {
            order: 3;
        }
        @include respond-below(lg) {
            display: flex;
            background: #FFF;
            padding: 10px;
            align-items: center;
            justify-content: center;
            border-radius: 3px;

            path {
                fill: #47763b;
            }
        }
    }
    &--icon {
        display: flex;
        align-items: center;
        gap: 1rem;
        position: relative;
        z-index: 3;
        @include respond-below(lg) {
            margin-inline-start: auto;
        }
        .lang {
            color: #fff;

            @include activeHeader() {
                color: #4D4E4E;
            }
        }
        .search-wrapper {
            position: relative;
        }
        .search-tap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-width: 200px;
            height: 100%;
            border: 0.7px solid #fff;
            border-radius: 30px;
            padding: 7px 14px;
            outline: none;
            z-index: -1;
            color: #fff;
            &::placeholder {
                color: #Fff;
                font-size: 14px;
                opacity: 0.65;
            }
            &::focus-visible {
                outline: none;
            }
            @include ltr() {
                left: calc(0% - 170px);
            }
            @include rtl() {
                right: calc(0% - 170px);
            }
        }
        .search-icon {
            /*display: inline-flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 0.5rem;
            color: #fff;
            font-size: 14px;
            border: 0.7px solid #fff;
            border-radius: 30px;
            background: linear-gradient(186deg, rgba(71,118,59,1) 0%, rgba(40,124,87,1) 51%, rgba(33,125,93,1) 56%, rgba(0,131,123,1) 100%);
            backdrop-filter: blur(30px) brightness(15);*/
            padding: 7px;

        }

        .search-icon {
            path {
                @include activeHeader() {
                    fill: #4D4E4E;
                }
            }
        }

        .setting-icon {
            cursor: pointer;
            path {
                @include activeHeader() {
                    fill: #4D4E4E;
                    stroke: #4D4E4E;
                }
            }
        }

        @include respond-below(xl) {
            button svg {
                width: 15px;
                height: auto;
            }

            .sec-logo img {
                width: 75px;
                height: auto;
            }
        }

        @include respond-below(sm) {
            height: auto;
            flex: 100% 0 0;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px;
        }
    }
}

.main-nav {
    display: block;
    align-items: center;
    justify-content: space-between;

    @include respond-below(xxl) {
        font-size: .8rem;
    }

    @include respond-below(lg) {
        font-size: 1rem;
    }

    @include respond-above(lg) {
        @include center("both");
        width: 100%;
        /*top: 80%;*/
    }


    .header--nav-btn {
        @include respond-below(lg) {
            display: block;
        }
    }

    .nav--bar {
        display: flex;
        justify-content: center;
        gap: 2rem;

        @include respond-below(xxxl) {
            gap: 1rem;
        }

        @include respond-below(xxl) {
            gap: 1rem;
        }



        @include respond-below(lg) {
            position: fixed;
            background-color: #fff;
            top: 0;
            width: 250px;
            height: 100%;
            overflow: auto;
            transition: all ease-in-out .5s;
            z-index: 9999;
            flex-direction: column;
            gap: .7rem;
            justify-content: flex-start;
            padding-top: 1.5rem;
            /*padding-inline-start: 1rem;*/
            display: flex;

            @include ltr() {
                left: -250px;
                padding-left: 1rem;
            }

            @include rtl() {
                right: -250px;
                padding-right: 1rem;
            }
        }
    }

    &--links {
        color: #fff;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: all .25s ease-in-out;
        font-weight: 600;

        @include activeHeader() {
            color: #4D4E4E;
        }

        &::before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23fff'/%3E%3C/svg%3E%0A");
            top: 0;
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 15px;
            transition: all ease-in-out .25s;
            background-size: contain;

            @include activeHeader() {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23D9C0B0'/%3E%3C/svg%3E%0A");
            }

            @include respond-between(lg, xl) {
                content: none;
            }

            @include respond-below(lg) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23D9C0B0'/%3E%3C/svg%3E%0A");
            }
        }

        &:hover {
            color: #D9C0B0 !important;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23D9C0B0'/%3E%3C/svg%3E%0A") !important;
            }
        }




        @include respond-below(lg) {
            color: #4D4E4E;
            padding-top: 0;
            border-bottom: 1px solid #F1F1F1;
            width: 100%;
        }
    }

    &--item {
        position: relative;

        .drop-menu-level2 {
            display: none;
            position: absolute;
            /*            inset-inline-start: calc(100% - 10px);*/
            background-color: #fff;
            border-radius: 10px;
            width: 210px;
            padding: 20px 0;
            box-shadow: 0px 3px 26px rgba(0, 0, 0, .1);
            z-index: 999;
            top: -10px;

            @include ltr() {
                left: calc(100% - 10px);
            }

            @include rtl() {
                right: calc(100% - 10px);
            }

            @include respond-below(lg) {
                display: none !important;
            }
        }

        .drop-menu {
            display: none;
            position: absolute;
            background-color: #fff;
            border-radius: 10px;
            width: 210px;
            padding: 20px 0;
            box-shadow: 0px 3px 26px rgba(0, 0, 0, .1);
            z-index: 999;

            @include respond-below(lg) {
                position: static;
            }

            li {
                margin-bottom: 10px;
                position: relative;
                padding-inline: 24px;

                a {
                    color: #3B3B3C;
                    display: inline-block;
                    border-bottom: 1px solid #3b3b3c44;
                    width: 100%;
                    position: relative;
                    padding-bottom: 10px;
                    transition: border-color ease-in-out .25s, color ease-in-out .25s;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -7px;
                        /* right: 0;*/
                        /*inset-inline-start: 0;*/
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%2346763a'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        width: 12px;
                        height: 12px;
                        transition: left, right ease-in-out .3s, background-image ease-in-out .55s;

                        @include rtl() {
                            right: 0;
                        }

                        @include ltr() {
                            left: 0;
                        }
                    }

                    &:hover {
                        color: #C8A791;
                        border-color: $gold;

                        &::after {
                            /*inset-inline-start: 100%;*/
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%23C8A791'/%3E%3C/svg%3E%0A");

                            @include rtl() {
                                right: 100%;
                            }

                            @include ltr() {
                                left: 100%;
                            }
                        }
                    }
                }

                &.has-dropdown:hover > a {
                    color: #C8A791;
                    border-color: $gold;

                    &::after {
                        @include ltr() {
                            right: 100%;
                        }

                        @include rtl() {
                            left: 100%;
                        }
                        /*inset-inline-start: 100%;*/
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%23C8A791'/%3E%3C/svg%3E%0A");
                    }
                }

                &:hover {
                    .drop-menu-level2 {
                        display: block;
                    }
                }
            }
        }

        &:hover {
            .drop-menu {
                display: block;
            }

            .main-nav--links {
                color: #D9C0B0 !important;

                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23D9C0B0'/%3E%3C/svg%3E%0A") !important;
                }
            }
        }

        @include respond-below(lg) {
            inset-inline-end: 0;
        }
    }
}

.header--mobile-overlay {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    background: rgba(61,57,53,0.7);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    backdrop-filter: blur(10px);
    z-index: 999;
    width: 100%;
    height: 100%;
    transition: opacity .35s ease-in-out, visibility .35s ease-in-out;

    @include respond-below(lg) {
        display: block;
    }

    @include respond-below(xl) {
        z-index: 999;
    }

    .close-btn {
        position: absolute;
        top: 30px;
        /*inset-inline-end: 30px;*/
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #00837B;
        cursor: pointer;
        transition: all ease-in-out .35s;
        z-index: 999;

        @include ltr() {
            right: 30px;
        }

        @include rtl() {
            left: 30px;
        }

        svg {
            width: 20px;
            height: 20px;
        }

        @include respond-below(md) {
            width: 50px;
            height: 50px;
        }

        @include respond-below(sm) {
            top: 20px;
            /*inset-inline-end: 10px;*/
            width: 35px;
            height: 35px;

            @include ltr() {
                right: 10px;
            }

            @include rtl() {
                left: 10px;
            }

            svg {
                width: 15px;
                height: 15px;
            }
        }

        &:hover {
            background-color: $gold;

            svg {
                transform: rotate(180deg);
            }
        }
    }
}

@media (min-width: 992px) {
    .header.scroll-active .top-header--icon .setting-icon path {
        fill: none;        
    }
}