@import 'about';
@import 'contact';
 
////start hero
/*.hero {
    color: #fff;
    position: relative;

    .hero--slider-btns-wrapper {
        position: absolute;
        width: 250px;
        bottom: 7rem;
        left: 3rem;
        z-index: 9;

        @include respond-below(md) {
            bottom: 3rem;
            left: 50%;
            transform: translateX(-50%);
        }

        @include respond-below(sm) {
            display: none;
        }
    }

    .swiper-button-next, .swiper-button-prev {
        bottom: 12.5%;
        top: unset;

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30.243' height='13.501' viewBox='0 0 30.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H37.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.027,1.027,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.251)' fill='%23fff'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;

            @include respond-below(xl) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.243' height='9.501' viewBox='0 0 30.243 13.501'%3E%3Cpath d='M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H37.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.027,1.027,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z' transform='translate(-7.882 -11.251)' fill='%23fff'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
            }
        }

        @include respond-below(sm) {
            display: none;
        }
    }

    .swiper-button-next {
        inset-inline-end: 35px;
    }

    .swiper-button-prev {
        inset-inline-end: 180px;
        right: auto;

        &::after {
            transform: rotate(180deg);
        }

        @include respond-below(xl) {
            inset-inline-end: 170px;
        }
    }

    .swiper-pagination {
        width: fit-content;
        inset-inline-end: 90px;
        bottom: 10.5%;
        font-size: 2.5rem;
        display: inline-flex;
        align-items: baseline;
        flex-direction: row-reverse;
        color: rgba(#FFF, 0.5);
        margin-bottom: -1rem;

        &-current {
            font-size: 4rem;
            color: rgba(#FFF, 1);
        }

        @include respond-below(xl) {
            font-size: 1.7rem;
            margin-bottom: -.6rem;

            &-current {
                font-size: 3.2rem;
            }
        }
    }

    .hero-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: center center no-repeat;
        background-attachment: fixed;
        background-size: cover;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url('@/assets/images/hero-pattern.svg') no-repeat center/cover;
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            background: radial-gradient(circle at center, rgba(#343434,0.1), rgba(#1B390D, .7));
        }

        .hero-slider-bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .hero-content {
        position: relative;
        z-index: 999;
        padding-block: 0.05px;
        min-height: 100vh;
        display: flex;
        align-items: flex-end;
    }

    .hero-more-link {
        @include respond-below(xl) {
            padding: 6px 10px;
            font-size: 0.85rem;

            &::after {
                margin-inline-start: 20px;
            }
        }

        @include respond-below(lg) {
            padding: 6px 10px;

            &::after {
                margin-inline-start: 15px;
            }
        }

        @include respond-below(sm) {
            &::after {
                margin-inline-start: 8px;
            }
        }
    }

    &--txt {
        max-width: 500px;
        position: relative;
        margin-bottom: 8rem;
        // @include center('v');
        .titl-with-shape {
            margin-bottom: 0rem;
        }

        h2 {
            font-size: 3.75rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1rem;
        }

        @include respond-below(xxl) {
            margin-top: 11rem;

            h2 {
                font-size: 2.5rem;
                margin-bottom: .8rem;
            }

            p {
                margin-bottom: .6rem;
            }
        }

    }

    .hero-icon {
        position: fixed;
        bottom: 23%;
        inset-inline-end: 100px;
        width: 42px;
        height: 42px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        //  background-color: #C8A791;
        border: 1px solid #fff;
        border-radius: 50%;
        backdrop-filter: blur(8px) brightness(75%) opacity(75%);

        @include respond-below(xl) {
            bottom: 35%;
        }

        @include respond-below(lg) {
            bottom: 15%;
        }

        @include respond-below(sm) {
            display: none;
        }
    }
}*/
/// start statics

/*.home-statistics {
    // padding: 3rem 0 3rem;
    position: relative;
    background: url("@/assets/images/statics.png") no-repeat center center/cover;
    overflow: hidden;
    background-attachment: fixed;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#343434, 0.35);
    }

    &__wrapper {
        display: flex;
        align-items: center;

        @include respond-below(xxl) {
            // flex-direction: column;
            flex-wrap: wrap;
            margin-top: 0;
        }
    }

    &--container {
        width: 50%;
        padding-inline-start: 2rem;
        z-index: 999;

        .titl-with-shape {
            color: #fff;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.434' height='16.215' viewBox='0 0 21.434 16.215'%3E%3Cpath d='M197.108,220.379c-.253-.156-.519-.319-.8-.484l-.506-.3-.5-.292c-.259-.149-.527-.3-.8-.454a.01.01,0,0,0-.009,0c-.271-.156-.551-.308-.836-.461a.157.157,0,0,0-.033-.016c-.286-.159-.58-.316-.881-.472s-.633-.328-.96-.494c-.159-.079-.321-.159-.483-.242l-.009,0,0,0,0,0a62.086,62.086,0,0,0-9.457,5.675,56.8,56.8,0,0,1,9.465,8.433,56.8,56.8,0,0,1,9.465-8.433c-.371-.288-1.408-1.049-3.02-2.064C197.532,220.641,197.323,220.512,197.108,220.379Z' transform='translate(-180.568 -216.219)' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            }
        }

        @include respond-below(xl) {
            flex-direction: column;
            margin-top: 0;
            width: 100%;
        }
    }

    &--slider {
        padding-inline-start: 6rem;
        width: 100%;
        //// active slideer

        .swiper-slide-active .statistics-item--wrapper {
            box-shadow: 0 0 0 10px #EFEFEF,0 0 0 40px rgba(#0D3C1E,.2),0 0 0 75px rgba(#0D3C1E,.1),0 0 0 105px rgba(#0D3C1E,.1),0 0 0 135px rgba(#0D3C1E,.1),0 0 0 180px rgba(#0D3C1E,.1),0 0 0 210px rgba(#0D3C1E,.45),0 0 0 240px rgba(#0D3C1E,.1),0 0 0 270px rgba(#0D3C1E,.05),0 0 0 300px rgba(#0D3C1E,.05);

            & + .statistics-item--home-item-bg {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1) !important;
            }
        }

        @include respond-below(xxl) {
            padding-inline-start: 3rem;
            overflow: visible;
        }

        @include respond-below(sm) {
            padding-inline-start: 0rem;
        }

        &-btns-wrapper {
            position: absolute;
            top: 50%;
            inset-inline-start: 20px;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            gap: 9rem;
            z-index: 99999;

            .slider-button--prev, .slider-button--next {
                transform: rotate(-90deg);

                @include respond-below(xxl) {
                    svg {
                        width: 20px;
                        height: 9;
                    }
                }
            }

            @include respond-below(sm) {
                display: none;
            }
        }

        &-pagination {
            bottom: 50% !important;
            transform: translateY(50%);
            left: 5px;
            display: inline-flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;

            .swiper-pagination-bullet {
                display: block;
                background: none;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%23fff'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                width: 13px;
                height: 10px;
            }
        }
    }

    &--ttl-wrapper {
        position: relative;
        z-index: 1;
        max-width: 430px;

        .home-statistics--txt {
            font-size: 1rem;
            color: #fff;
            margin-top: .5rem;
            margin-bottom: 0;

            @include respond-below(xxl) {
                font-size: 0.85rem;
            }

            @include respond-below(sm) {
                font-size: 0.65rem;
            }
        }

        @include respond-below(xxl) {
            max-width: 430px;
            margin-top: 3rem;
        }

        @include respond-below(sm) {
            max-width: 220px;
            margin-top: 1.5rem;
        }
    }

    &--ttl {
        font-size: 3rem;
        font-weight: 600;
        position: relative;
        margin-bottom: .75rem;
        color: #fff;

        @include respond-below(xxl) {
            font-size: 2.5rem;
            margin-bottom: .5rem;
        }

        @include respond-below(sm) {
            font-size: 1.5rem;
        }
    }

    &--slider-wrapper {
        position: relative;
        z-index: 2;
        pointer-events: none;
        margin-top: -170px;

        .statistics-item--wrapper {
            &:before {
                display: block;
            }
        }
    }

    .swiper {
        .swiper-slide {
            padding: 140px 0 130px 0;

            @include respond-below(lg) {
                padding: 150px 0 150px;
            }
        }
    }
}
*/
/////
/*.news {
    padding-bottom: 3.8rem;
    padding-top: 2rem;
    position: relative;

    @include respond-below(xxl) {
        padding-bottom: 3rem;
        padding-top: 2rem;
    }

    @include respond-below(sm) {
        padding-bottom: 1.5rem;
        padding-top: 1rem;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('@/assets/images/news-bg.svg') no-repeat;
    }

    h2 {
        font-size: 3.75rem;
        font-weight: 600;
        color: $green-dark;
        margin-bottom: 1rem;

        @include respond-below(xxl) {
            font-size: 2.75rem;
            margin-bottom: 0.8rem;
        }

        @include respond-below(xl) {
            font-size: 2.25rem;
            margin-bottom: 0.8rem;
        }

        @include respond-below(lg) {
            font-size: 1.75rem;
            margin-bottom: 0.5rem;
        }

        @include respond-below(sm) {
            font-size: 1.25rem;
            margin-bottom: 0rem;
        }
    }

    .news__content {
        display: flex;
        align-items: center;
        gap: 20px;

        @include respond-below(xl) {
            flex-wrap: wrap;
        }
    }

    &__main__wrapper {
        width: 50%;

        @include respond-below(xl) {
            width: 100%;
        }

        .news-content-main-card {
            margin-top: 1rem;
            position: relative;
            width: fit-content;

            .card-photo {
                z-index: 9;

                img {
                    width: 780px;
                    max-height: 480px;
                }
            }

            .opportunity-card {
                position: relative;
                max-width: 700px;
                margin-top: -120px;
                margin-bottom: 15px;
                z-index: 99;

                p {
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                }

                @include respond-below(xxxl) {
                    padding: 15px 24px;
                    max-width: 550px;

                    .card-info {
                        font-size: .75rem;

                        .card-date {
                            span:nth-of-type(1)::after {
                                margin-inline: 4px;
                            }
                        }
                    }

                    &--titl {
                        font-size: 1.25rem;
                    }

                    p {
                        font-size: .75rem;
                    }
                }

                @include respond-below(xxl) {
                    padding: 10px 18px;
                    max-width: 450px;
                }

                @include respond-below(xl) {
                    padding: 10px 18px;
                    max-width: 550px;
                    margin-inline: auto;
                }

                @include respond-below(md) {
                    margin-top: -50px;
                    max-width: 350px;

                    .card-info {
                        font-size: .5rem;

                        .card-date {
                            span:nth-of-type(1)::after {
                                margin-inline: 2px;
                                width: 10px;
                                height: 6px;
                            }
                        }

                        svg {
                            width: 8px;
                        }
                    }

                    &--titl {
                        font-size: 1rem;
                        margin-top: 0.5rem;
                        margin-bottom: 0.3rem;
                    }

                    p {
                        font-size: .65rem;
                        margin-bottom: 0.8rem;
                    }
                }

                @include respond-below(sm) {
                    max-width: 280px;

                    &--titl {
                        font-size: 0.8rem;
                        margin-top: 0.6rem;
                        margin-bottom: 0.2rem;
                    }

                    p {
                        font-size: 0.55rem;
                        margin-bottom: 0.6rem;
                    }
                }
            }
        }
    }

    &__sec__wrapper {
        position: relative;
        width: 50%;
        padding-bottom: 90px;

        @include respond-below(xl) {
            width: 100%;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: .8rem;

            h3 {
                font-size: 2rem;
                color: $green-dark;

                @include respond-below(xxl) {
                    font-size: 1.5rem;
                }

                @include respond-below(lg) {
                    font-size: 1.25rem;
                }

                @include respond-below(sm) {
                    font-size: 1rem;
                }
            }
        }

        .news__card__box {
            position: relative;
            //max-width: 210px;
            // @include respond-below(xxl) {
            //   margin-inline: auto;
            //   max-width: 180px;
            // }
            @include respond-below(sm) {
                margin-inline: auto;
                max-width: 210px;
            }

            &__photo {
                position: relative;

                img {
                    width: -webkit-fill-available;
                    height: auto;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(205deg, rgba(217,192,176,0) 0%, rgba(82,116,66,1) 100%);
                    opacity: 0.9;
                }
            }

            .secondary-titl {
                color: #3B3B3C;
                opacity: .75;
                font-weight: 600;
                margin-bottom: 0px;
                word-spacing: 1px;

                @include respond-below(sm) {
                    font-size: 0.7rem;
                }
            }
        }

        .swiper-button-next, .swiper-button-prev {
            top: unset;
            bottom: -15px;
        }

        .news__sec__wrapper-swiper-2-nxt {
            inset-inline-start: 5%;
            transform: rotate(180deg);
        }

        .news__sec__wrapper-swiper-2-prev {
            inset-inline-start: 15%;
        }

        .swiper-pagination-3 {
            display: flex;
            justify-content: space-between;
            left: 0%;
            bottom: 3px;
            width: 70%;
        }

        .news-btn-wrapper {
            position: relative;
            margin-top: 3rem;
            height: 9px;
        }

        .swiper-scrollbar {
            position: absolute;
            bottom: 8px;
            left: 4px;
            height: 2px;
            width: calc(70% - 8px);
            background: rgba($green-dark, 0.2);

            .swiper-scrollbar-drag {
                background: $green-dark;
            }
        }

        .swiper-pagination-bullet {
            background: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%2346763a'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            width: 13px;
            height: 10px;
            border-radius: 0;
            margin: 0 !important;

            &::after {
                content: '';
                width: 30px;
                height: 1px;
                background-color: #2E3B2B;
            }
            // @include respond-below(sm) {
            //   padding-inline: 10px;
            // }
        }
    }
}*/
/// end news

/// start opportunity
/*.opportunity {
    padding-top: 5rem;
    padding-bottom: 10rem;
    position: relative;
    z-index: 999;
    // min-height: 500px;
    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(@/assets/images/opport-bg.svg);
        background-repeat: repeat-x;
        background-size: contain;
        background-color: #F5F5F5;
        z-index: -1;
    }

    .opportunity-content {
        position: relative;

        .more-link {
            position: absolute;
            top: -5px;
            inset-inline-end: 0;
        }
    }

    .titl-with-shape {
        margin-bottom: 0px;
    }

    h2 {
        color: $green-dark;
        font-size: 3.75rem;
        margin-bottom: 1.25rem;
    }

    .nav-tabs {
        border: none;
        margin-bottom: 3.75rem;

        .nav-link {
            border: none;
            background: none;
            color: #B9B9B9;
            font-size: 2rem;

            &:hover {
                color: $green-dark;
            }

            &.active {
                color: $green-dark;
                border-bottom: 2px solid $green-dark;
            }
        }
    }

    @include respond-below (xxxl) {
        .opportunity-card {
            padding: 15px 24px;

            .card-info {
                font-size: .65rem;

                .card-date {
                    span:nth-of-type(1)::after {
                        margin-inline: 4px;
                    }
                }
            }

            .card-icon {
                gap: 4px;

                svg {
                    width: 9px;
                }
            }

            &--titl {
                font-size: 1rem;
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
            }

            p {
                font-size: .75rem;
            }

            @include respond-below(sm) {
                padding: 10px 18px;

                &--titl {
                    font-size: .8rem;
                    margin-bottom: .5rem;
                }

                p {
                    font-size: .65rem;
                    margin-bottom: .35rem;
                }
            }
        }
    }

    @include respond-below (xxl) {
        padding-top: 4rem;
        padding-bottom: 8rem;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 0.75rem;
        }

        .nav-tabs {
            margin-bottom: 3rem;

            .nav-link {
                font-size: 1.25rem;
            }
        }
    }

    @include respond-below (lg) {
        padding-top: 3rem;
        padding-bottom: 6rem;

        h2 {
            font-size: 2rem;
            margin-bottom: 0.9rem;
            margin-top: .5rem;
        }

        .nav-tabs {
            margin-bottom: 2.25rem;

            .nav-link {
                font-size: 1rem;
            }
        }
    }

    @include respond-below (sm) {
        h2 {
            font-size: 1rem;
        }

        .nav-tabs {
            margin-bottom: 1rem;

            .nav-link {
                font-size: 0.8rem;
            }
        }
    }

    .swiper-container {
        padding-inline-start: 4rem;
        position: relative;

        .swiper-button-next, .swiper-button-prev {
            inset-inline-start: 0;
        }

        .swiper-button-next {
            top: 4%;
            transform: rotate(90deg);
        }

        .swiper-button-prev {
            top: 94%;
            transform: rotate(-90deg);
        }

        .swiper-pagination {
            transform: rotate(90deg);
            inset-inline-start: -44px;
            bottom: 45%;
            width: fit-content;

            .swiper-pagination-bullet {
                background: none;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.279' height='9.898' viewBox='0 0 13.279 9.898'%3E%3Cpath d='M10.723,2.26c-.178-.109-.364-.224-.558-.34l-.355-.21-.354-.2c-.182-.1-.37-.212-.564-.319a.007.007,0,0,0-.006,0C8.7,1.075,8.5.968,8.3.861A.1.1,0,0,0,8.276.85c-.2-.112-.407-.221-.618-.331S7.214.289,6.985.172C6.873.116,6.759.06,6.646,0l0,0h0s0,0,0,0A43.481,43.481,0,0,0,0,3.983,39.826,39.826,0,0,1,6.639,9.9a39.827,39.827,0,0,1,6.639-5.915c-.26-.2-.988-.736-2.118-1.448-.141-.091-.287-.182-.438-.275' fill='%2346763a'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                width: 20px;
                height: 13px;
                transform: rotate(270deg);
            }
        }

        @include respond-below(sm) {
            padding-inline: 10px;

            .swiper-pagination {
                bottom: -60px;
                inset-inline-start: 50%;
                transform: translatex(50%);

                .swiper-pagination-bullet {
                    transform: rotate(0deg);
                }
            }

            .swiper-button-next {
                top: unset;
                transform: rotate(0deg);
                inset-inline-start: 70%;
            }

            .swiper-button-prev {
                top: unset;
                transform: rotate(180deg);
                inset-inline-start: 23%;
            }

            .swiper-button-next, .swiper-button-prev {
                top: unset;
                bottom: -66px;
            }
        }
    }
}*/

///// start places

.places {
    padding: 5rem 0;
    position: relative;

    @include respond-below(xl) {
        padding: 3rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 10px;
        inset-inline-end: 0px;
        width: 100%;
        height: 100%;
        background: url('../public/content-images/places/pattern.svg') no-repeat right top;
        z-index: -1;
        background-size: cover;
        opacity: .2;
        pointer-events: none;
    }

    &__content {
        h2 {
            font-size: 3.5rem;
            color: $green-dark;
            font-weight: 600;

            @include respond-below(xl) {
                font-size: 2.5rem;
            }

            @include respond-below(lg) {
                font-size: 1.75rem;
            }

            @include respond-below(sm) {
                font-size: 1.5rem;
            }
        }

        &__map {
            margin-top: 3.5rem;
            position: relative;

            @include respond-below(xl) {
                margin-top: 2rem;
            }

            .map-shapes {
                span {
                    position: absolute;
                    top: 90px;
                    inset-inline-start: 52%;
                    width: 40px;
                    height: 60px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='60.724' height='86.563' viewBox='0 0 60.724 86.563'%3E%3Cdefs%3E%3Cfilter id='Icon_ionic-ios-pin' x='0' y='0' width='60.724' height='86.563' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='16' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Icon_ionic-ios-pin)'%3E%3Cpath id='Icon_ionic-ios-pin-2' data-name='Icon ionic-ios-pin' d='M28.737,3.375c-11.517,0-20.862,8.678-20.862,19.37,0,15.067,20.862,40.9,20.862,40.9S49.6,37.811,49.6,22.745C49.6,12.053,40.254,3.375,28.737,3.375Zm0,27.656a6.795,6.795,0,1,1,6.795-6.795A6.795,6.795,0,0,1,28.737,31.031Z' transform='translate(1.63 -2.88)' fill='%233fa525' stroke='%23fff' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    z-index: 5;
                    transition: all ease-in-out 0.5s;
                    transform: scale(0.5);
                    opacity: 0.8;

                    &.active {
                        transform: scale(1);
                        opacity: 1;
                    }

                    &:nth-of-type(2) {
                        top: 170px;
                        inset-inline-start: 44.3%;
                    }

                    &:nth-of-type(3) {
                        top: 180px;
                        inset-inline-start: 64%;
                    }

                    &:nth-of-type(4) {
                        top: 250px;
                        inset-inline-start: 52%;
                    }

                    &:nth-of-type(5) {
                        top: 50px;
                        inset-inline-start: 62%;
                    }

                    &:nth-of-type(6) {
                        top: 350px;
                        inset-inline-start: 65%;
                    }
                }
            }
        }

        &__slider {
            position: relative;
            padding: 0 1rem;
            margin-top: -2rem;

            @include respond-below(sm) {
                //padding: 1rem;
                margin-top: 1rem;
            }

            .swiper-button-next {
                &::after {
                    transform: rotate(-180deg);
                }
            }

            .slider-content {
                box-shadow: 0px 0px 25px rgba(#000, 0.06);
                border-radius: 5px;
                overflow: hidden;
                transition: all ease-in-out 0.5s;

                div {
                    position: relative;
                    background-color: #fff;
                    margin-bottom: -1px;
                    aspect-ratio: 16/9;

                    &::after {
                        content: '';
                        background: rgba(#000, 0.6);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all ease-in-out 0.5s;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        opacity: 0.8;
                        transition: all ease-in-out 0.5s;
                        object-fit: cover;
                    }
                }

                h4 {
                    font-size: 1.18rem;
                    margin-bottom: 5px;
                    padding-inline: 2rem;
                    margin-top: 1.5rem;
                    font-weight: 600;
                    min-height: 45px;

                    @include respond-below(lg) {
                        font-size: 1rem;
                        margin-top: 0.75rem;
                        padding-inline: 1rem;
                    }
                }

                p {
                    font-size: 0.68rem;
                    margin-bottom: 2rem;
                    padding-inline: 2rem;

                    @include respond-below(lg) {
                        font-size: 0.6rem;
                        margin-bottom: 1rem;
                        padding-inline: 1rem;
                    }
                }
            }

            .swiper-button-prev, .swiper-button-next {
                left: unset;
                right: unset;

                @include respond-below(sm) {
                    display: none;
                }
            }

            .swiper-button-prev {
                inset-inline-end: -20px;
            }

            .swiper-button-next {
                inset-inline-start: -20px;
            }

            .swiper-slide-active {
                .slider-content {
                    background-color: $green-dark;
                    color: #fff;

                    div {
                        background-color: transparent;

                        &::after {
                            background: none;
                        }

                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
